import React from 'react';
import CallIcon from '../../images/phone-volume-solid.svg';
import { HiPaperAirplane } from 'react-icons/hi';

function index({ textValue, handleTextChange, handleTextSubmit, handleCallClick, isCallActive }) {
  return (
    <div className="mt-auto flex flex-col items-center">
    <div className="w-full p-2 rounded-lg bg-[#e8e8e8]">
      <textarea placeholder="Type a message..." value={textValue} onChange={handleTextChange}
        className="flex-grow w-full p-2 bg-[#e8e8e8] rounded-l-md focus:outline-none border-none resize-none overflow-hidden" rows={2} />
      <div className="flex space-x-2 mt-2 justify-between py-10">
        <div className={`flex items-center justify-center w-8 h-8 rounded-full hover:bg-[#dfd9d9] cursor-pointer ${ isCallActive ? 'blinking-icon' : ''}`}
          onClick={handleCallClick}
          style={{backgroundColor: isCallActive ? '#d20612' : '#00ba4d', }} >
          <img src={CallIcon} alt="Call Icon" style={{ width: '15px' }} />
        </div>
        <div className="flex items-center justify-center w-8 h-8 rounded-full hover:bg-[#dfd9d9] cursor-pointer"
        style={{ backgroundColor: '#50d3ff' }} onClick={handleTextSubmit} >
          <HiPaperAirplane className="text-lg transform rotate-90" style={{ color: 'white' }} />
        </div>
      </div>
    </div>
  </div>
  )
}

export default index
