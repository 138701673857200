import Chat from './Chat/Chat.js'
import Open from './Openchat/Open.js';
import { useState } from 'react';
function App() {
  const [chatStart, setChatStart] = useState(false)
  const StartChat = ()=>{
    setChatStart(!chatStart)
  }
  return (
    <>     
      {
        chatStart ?
      <Chat Close={StartChat}/> : <Open Open={StartChat}/>
      }
    </>
  );
}

export default App;
