import React, { useEffect, useRef, useState } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { useSpeechSynthesis } from "react-speech-kit";
import io from "socket.io-client";
import Audio from "../Audio/mp3.mp3";
import Audio2 from "../Audio/soundend.mp3";
import Audio3 from "../Audio/office-sound.mp3";
import "./chat.css";
import Header from "../Chat/Header";
import Messages from '../Chat/Messages';
import FormInput from '../Chat/FormInput';

// const socket = io("https://api.susan.fi/");
const socket = io("http://localhost:5000/");

function Chat(props) {
  const [isBellVisible, setBellVisible] = useState(false);
  const [isUserSpeaking, setIsUserSpeaking] = useState(false);
  const [isDivVisible, setDivVisible] = useState(false);
  const [isCallActive, setIsCallActive] = useState(false);
  const [botResponse, setBotResponse] = useState("");
  const [messages, setMessages] = useState([]);
  const [textValue, setTextValue] = useState("");
  const [visibleAvatar, setVisibleAvatar] = useState(false);
  const [selectedVoice, setSelectedVoice] = useState(null);
  const [voices, setVoices] = useState([]);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [isBotSpeaking, setIsBotSpeaking] = useState(false);

  const audioRef = useRef(null);
  const audioRef2 = useRef(null);
  const audioRef3 = useRef(null);
  const messageEndRef = useRef(null);
  const { speak, cancel } = useSpeechSynthesis();
  const { transcript, resetTranscript } = useSpeechRecognition();

  // Load available voices for speech synthesis
  useEffect(() => {
    const loadVoices = () => {
      const availableVoices = window.speechSynthesis.getVoices();
      setVoices(availableVoices);
      if (availableVoices.length > 0 && !selectedVoice) {
        setSelectedVoice(availableVoices[2]);
      }
    };

    loadVoices();
    window.speechSynthesis.onvoiceschanged = loadVoices;
  }, [selectedVoice]);

  // Initialize WebRTC with Echo Cancellation
  useEffect(() => {
    const initWebRTCWithEchoCancellation = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: {
            echoCancellation: true, // Enable echo cancellation
          },
        });
        // Attach the stream to your audio context or STT pipeline
        // For example, you might need this stream for further processing if required
        console.log("WebRTC audio stream initialized with echo cancellation:", stream);
      } catch (error) {
        console.error("Error accessing media devices with echo cancellation:", error);
      }
    };

    initWebRTCWithEchoCancellation();
  }, []);

  // Handle incoming bot responses
  useEffect(() => {
    const handleBotResponse = (response) => {
      console.log("Received bot response:", response);
      setMessages((prevMessages) => [
        ...prevMessages,
        { type: "bot", text: response },
      ]);
      setBotResponse(response);
      
      // Speak the response if not interrupted
      if (isCallActive) {
        speakResponse(response);
      }
    };

    socket.on("botResponse", handleBotResponse);

    // Cleanup the listener when the component unmounts
    return () => {
      socket.off("botResponse", handleBotResponse);
    };
  }, [isCallActive]);

  // Speak the bot response
  const speakResponse = (response) => {
    if (!isUserSpeaking) { // Only speak if the user is not speaking
      cancel(); // Stop any ongoing speech before speaking new response
      if (response) {
        console.log("Speaking response:", response);
        setIsBotSpeaking(true); // Set flag when bot starts speaking
        if (selectedVoice) {
          speak({
            text: response,
            voice: selectedVoice,
          
            onEnd: () => {
              setIsBotSpeaking(false); // Reset flag when done speaking
            },
          });
        } else {
          speak({
            text: response,
            onEnd: () => {
              setIsBotSpeaking(false); // Reset flag when done speaking
            },
          });
        }
      }
    }
  };

  // Handle voice commands and typing
  useEffect(() => {
    if (transcript) {
      setIsUserSpeaking(true); // Set user speaking flag to true when transcript is generated
  
      // If bot is speaking, cancel the bot's speech synthesis
      if (isBotSpeaking) {
        cancel(); // Immediately stop bot's speech
        setIsBotSpeaking(false); // Reset bot speaking flag
      }
  
      const endCallCommands = [
        "end call",
        "hang up",
        "stop call",
        "and call",
        "goodbye",
        "terminate call",
      ];
  
      if (endCallCommands.some((cmd) => transcript.toLowerCase().includes(cmd))) {
        endCall(); // Handle end call commands
      }
  
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
  
      // Set a timeout to send the message after 900ms of silence
      setTypingTimeout(
        setTimeout(() => {
          socket.emit("userMessage", transcript); // Send user's message
          setMessages((prevMessages) => [
            ...prevMessages,
            { type: "user", text: transcript },
          ]);
          resetTranscript(); // Clear transcript
          setIsUserSpeaking(false); // Reset user speaking flag after processing
        }, 900)
      );
    }
  }, [transcript, isBotSpeaking]);
  

  // Start listening for voice input
  const startListening = () => {
    setVisibleAvatar(true);
    cancel(); // Stop any ongoing speech synthesis
    SpeechRecognition.startListening({ continuous: true });
  
    if (audioRef3.current) {
      audioRef3.current.loop = true;
      audioRef3.current.volume = 0.4;
      audioRef3.current.play();
    }
  };
  
  useEffect(() => {
    if (isUserSpeaking) {
      cancel(); // Cancel bot response if user speaks
      setIsBotSpeaking(false); // Reset bot speaking flag
    }
  }, [isUserSpeaking]);

  // Stop listening for voice input
  const stopListening = () => {
    if (audioRef3.current) {
      audioRef3.current.pause();
      audioRef3.current.currentTime = 0; // Reset to the beginning
    }
    SpeechRecognition.stopListening();
  };

  // Handle text input change
  const handleTextChange = (event) => {
    setTextValue(event.target.value);
  };

  // Handle text input submission
  const handleTextSubmit = (event) => {
    event.preventDefault(); // Prevents new line
    socket.emit("userMessage", textValue);
    setMessages((prevMessages) => [
      ...prevMessages,
      { type: "user", text: textValue },
    ]);
    setTextValue(""); // Clear the input field
  };

  // End the call and reset state
  const endCall = () => {
    setVisibleAvatar(false);
    setIsCallActive(false);
    stopListening();
    cancel();
    setBotResponse("");
    resetTranscript();

    if (audioRef2.current) {
      audioRef2.current.currentTime = 2;
      audioRef2.current.loop = false;
      audioRef2.current.play();
    }

    setTimeout(() => {
      if (audioRef2.current) {
        audioRef2.current.pause();
        audioRef2.current.currentTime = 0;
      }
    }, 2000);
  };

  // Handle call button click
  const handleCallClick = () => {
    if (isCallActive) {
      endCall();
      return;
    }
    setIsCallActive(true);
    setBellVisible(true);
    setDivVisible(true);

    if (audioRef.current) {
      audioRef.current.currentTime = 7;
      audioRef.current.loop = true;
      audioRef.current.play();
    }

    setTimeout(() => {
      setBellVisible(false);
      setDivVisible(false);
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
      startListening();
    }, 5000);
  };

  // Scroll to the bottom of the message list
  useEffect(() => {
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <div className="chatbox bg-white rounded-lg shadow-lg">
      <div className="backdrop-blur-md bg-green bg-opacity-30 rounded-lg h-full p-4 flex flex-col">
        {/* header of the chatbot */}
        <Header visibleAvatar={visibleAvatar} isDivVisible={isDivVisible} Close={props.Close}/>
        <div className="flex-grow overflow-y-auto scrollable-content">
          {messages.map((message, index) => (
            <Messages key={index} type={message.type} text={message.text} />
          ))}
          {/* Scroll anchor */}
          <div ref={messageEndRef} />
        </div>
         {/* Use the MessageInput component */}
         <FormInput textValue={textValue} handleTextChange={handleTextChange} handleTextSubmit={handleTextSubmit} 
         handleCallClick={handleCallClick} isCallActive={isCallActive}/>
      </div>
      <audio ref={audioRef}>
        <source src={Audio} type="audio/mp3" />
        Your browser does not support the audio tag.
      </audio>
      <audio ref={audioRef2}>
        <source src={Audio2} type="audio/mp3" />
        Your browser does not support the audio tag.
      </audio>
      <audio ref={audioRef3}>
        <source src={Audio3} type="audio/mp3" />
        Your browser does not support the audio tag.
      </audio>
    </div>
  );
}

export default Chat;
