import React from 'react'

function index({ type, text }) {
  return (
    <div key={index} className={`flex mb-4 ${type === "user" ? "justify-end" : "justify-start"}`} 
    style={{ justifyContent: type === "user" ? "right" : "left",}} >
    <div className={`rounded-lg shadow-md ${ type === "user" ? "justify-contn" : "bg-[#f3f1f1] text-black" }`}
      style={{ width: "70%", padding: "0.5rem 1rem", borderRadius: "15px", borderBottomRightRadius: type === "user" ? "0px" : "15px", 
        borderBottomLeftRadius: type === "bot" ? "0px" : "15px", backgroundColor: type === "user" ? "#1a73e8" : "#f3f1f1", 
        color: type === "user" ? "white" : "black",
      }} >
      <p>{text}</p>
    </div>
  </div>
  )
}

export default index
