import React from 'react';
import Susan from '../images/susan.png';
import './open.css'
function Open(props) {
  return (
<div 
  className="absolute bottom-3 right-4 p-2 glassmorphism rounded-lg shadow-lg z-60 cursor-pointer shivering" 
  onClick={props.Open}
>
  <img src={Susan} alt="Susan" style={{ width: "35px" }} />
</div>


  )
}

export default Open
